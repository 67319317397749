var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    _vm._b(
      {
        attrs: {
          visible: _vm.visible,
          "custom-class": "imelink-relay-tooltip",
          "append-to-body": true
        },
        on: {
          "update:visible": function($event) {
            _vm.visible = $event
          }
        },
        scopedSlots: _vm._u(
          [
            {
              key: "footer",
              fn: function() {
                return [_vm._t("footer")]
              },
              proxy: true
            }
          ],
          null,
          true
        )
      },
      "el-dialog",
      _vm.$attrs,
      false
    ),
    [
      _c("h1", { staticClass: "el-icon-s-promotion" }),
      _c(
        "el-row",
        { staticClass: "imelink-relay-tooltip__container" },
        _vm._l(_vm.control.selectionData, function(item) {
          return _c(
            "el-col",
            { key: item.id, attrs: { lg: 6, md: 8 } },
            [
              _c("imelink-contact", {
                attrs: {
                  data: {
                    ...item,
                    ...{
                      groupOwnerId: "",
                      lastMessageContent: "",
                      lastMessageTime: "",
                      translate: "",
                      translateUserId: "",
                      lastMessageType: "",
                      originSenderId: "",
                      originSender: "",
                      remarks: [],
                      unread: 0
                    }
                  },
                  "no-hover": ""
                }
              })
            ],
            1
          )
        }),
        1
      ),
      _c("el-divider"),
      _c("div", {
        ref: "content",
        staticClass: "imelink-relay-tooltip__content"
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }