<template>
  <el-dialog
    :visible.sync="visible"
    custom-class="imelink-relay-tooltip"
    v-bind="$attrs"
    :append-to-body="true"
  >
    <h1 class="el-icon-s-promotion"></h1>
    <el-row class="imelink-relay-tooltip__container">
      <el-col
        :lg="6"
        :md="8"
        v-for="item in control.selectionData"
        :key="item.id"
      >
        <imelink-contact
          :data="{
            ...item,
            ...{
              groupOwnerId: '',
              lastMessageContent: '',
              lastMessageTime: '',
              translate: '',
              translateUserId: '',
              lastMessageType: '',
              originSenderId: '',
              originSender: '',
              remarks: [],
              unread: 0,
            },
          }"
          no-hover
        />
      </el-col>
    </el-row>
    <el-divider></el-divider>
    <div class="imelink-relay-tooltip__content" ref="content"></div>
    <template #footer>
      <slot name="footer"></slot>
    </template>
  </el-dialog>
</template>

<script>
import ImelinkContact from "./contact-item";
export default {
  name: "SelectionConfirm",
  inject: ["control"],
  components: {
    ImelinkContact,
  },
  data() {
    return {
      visible: false,
    };
  },
  computed: {},
  watch: {},
  created() {},
  methods: {
    // eslint-disable-next-line no-unused-vars
    open(options) {
      options = {
        ...{
          cancel() {},
          confirm() {},
        },
        ...options,
      };
      this.visible = true;
      this.appendRelayContent();
    },
    async appendRelayContent() {
      await this.$nextTick();

      const data = this.control.relayMessageCache;
      const component = this.control.getMessageComponent(data.id);
      this.$refs.content.innerHTML = component.$el.outerHTML;
      this.removeTransList();
    },
    removeTransList() {
      const contentElement = this.$refs.content;
      const transListElements = Array.from(
        contentElement.getElementsByClassName("trans_list")
      );
      transListElements.forEach((element) => {
        element.parentNode.removeChild(element);
      });
    },
    close() {
      this.visible = false;
    },
  },
};
</script>

<style lang="stylus">
@import '~styles/utils/index';

queStyle() {
  position: absolute;
  font-size: 80px;
  font-family: Arial;
  opacity: 0.4;
}


+b(imelink-relay-tooltip) {
  .imelink-message-text__translate-edit, .imelink-message-text__translate, .imelink-message-translate__avatar, .imelink-message__origin {
    display: none;
  }


  .el-dialog__header {
    padding: 0;
  }

  .el-dialog__body {
    max-height: 70vh;
    overflow-y: auto;
    padding-top: 8px;
    padding-bottom: 10px;
  }
  .el-dialog__body::-webkit-scrollbar{
    width: 0 !important
  }


  +e(friends) {
    overflow: hidden;
    scrollbar-light();
    overflow-y: auto;
    height: 300px;
  }

  +e(container) {
    max-height: 300px;
    overflow: hidden;
    scrollbar-light();
    overflow-y: scroll;
  }

  +e(content) {
    padding: 40px 20px;
    padding-bottom: 20px;
    position: relative;

    &:after {
      queStyle();
      content: '“';
      top: 0;
      left: 0;
      display: none;
    }

    &:before {
      queStyle();
      content: '”';
      bottom: -40px;
      right: 0;
      pointer-events: none;
      display: none;
    }
  }
}
</style>
